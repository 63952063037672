var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WarningDialog',{ref:"WarningDialogRef",attrs:{"loading":_vm.deleting},on:{"action":_vm.deleteExpense}}),_c('v-dialog',{attrs:{"width":"1200","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showExpenseForm = false}},model:{value:(_vm.showExpenseForm),callback:function ($$v) {_vm.showExpenseForm=$$v},expression:"showExpenseForm"}},[_c('v-card',{staticClass:"pa-sm-10 pa-5"},[_c('div',{staticClass:"text-right"},[_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showExpenseForm = false}}},[_vm._v(" mdi-close ")])],1),(_vm.showExpenseForm)?_c('ExpenseForm',{attrs:{"expense":_vm.expense,"title":_vm.formTitle},on:{"expense-updated":_vm.handleExpenseUpdated,"expense-created":_vm.handleExpenseCreated,"on-cancel":function($event){_vm.showExpenseForm = false}}}):_vm._e()],1)],1),_c('p',{staticClass:"caption pasive--text text-right"},[_vm._v(" "+_vm._s(_vm.totalExpenses)+" "+_vm._s(_vm.totalExpenses == 1 ? "resultado" : "resultados")+" ")]),_c('v-data-table',{class:_vm.clickable ? 'row-pointer' : '',attrs:{"headers":_vm.listHeaders,"items":_vm.expenses,"loading":_vm.loading,"loading-text":"Cargando...","no-results-text":'Cargando...',"no-data-text":_vm.filter === '' ? 'No hay gastos registrados' : 'Gasto no encontrado',"item-key":"id","item-class":"text-center","disable-sort":"","footer-props":{
      itemsPerPageText: 'Resultados por pág.',
      itemsPerPageAllText: 'Todos',
      pageText: '',
      showCurrentPage: true,
      itemsPerPageOptions: [15, 30, 60],
    },"options":_vm.options,"server-items-length":_vm.totalExpenses},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.total",fn:function(props){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatMoney(props.item.total)))])]}},{key:"item.date",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.item.date))+" ")]}},{key:"item.updatedAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(props.item.updatedAt))+" ")]}},{key:"item.location",fn:function(props){return [_vm._v(" "+_vm._s(props.item.location ? props.item.location.name : "-")+" ")]}},{key:"item.comment",fn:function(props){return [_vm._v(" "+_vm._s(props.item.comment ? props.item.comment : "-")+" ")]}},{key:"item.action",fn:function(props){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-menu',{attrs:{"bottom":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"text":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleClickEdit(props.item)}}},[_c('v-list-item-content',{staticClass:"font-weight-medium"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"18px"}},[_vm._v("mdi-square-edit-outline")]),_c('div',{staticClass:"subtitle-2 font-weight-regular"},[_vm._v("Modificar")])],1)])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showWarning(props.item)}}},[_c('v-list-item-content',{staticClass:"font-weight-medium"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"18px"}},[_vm._v(" mdi-delete ")]),_c('div',{staticClass:"subtitle-2 font-weight-regular"},[_vm._v("Eliminar")])],1)])],1)],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }