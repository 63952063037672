<template>
  <div>
    <div v-if="loading" class="d-flex justify-center mt-5">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-slide-x-reverse-transition mode="out-in">
      <div v-if="!loading">
        <v-row>
          <v-col cols="12" md="3">
            <v-card class="pa-5" style="border-radius: 8px" elevation="6">
              <InfoItem
                label="Efectivo"
                :icon="'mdi-cash'"
                :val="paid.cashSum"
              />
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="pa-5" style="border-radius: 8px" elevation="6">
              <InfoItem
                label="Tarjeta"
                :icon="'mdi-credit-card'"
                :val="paid.creditCardSum"
              />
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="pa-5" style="border-radius: 8px" elevation="6">
              <InfoItem
                label="Transferencia o cheque"
                :icon="'mdi-bank'"
                :val="paid.bankSum"
              />
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="pa-5" style="border-radius: 8px" elevation="6">
              <InfoItem label="Total" :val="paid.totalSum" />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { formatCurrency } from "../../helpers/formatters";
import InfoItem from "../InfoItem.vue";

export default {
  name: "InvoicePaymentStats",
  components: {
    InfoItem,
  },
  data() {
    return {
      loading: true,
      data: null,

      // filters
      dateFrom: "",
      dateTo: "",
      patientId: "",
      locationId: "",
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    paid() {
      return {
        totalSum: this.formatMoney(parseFloat(this.data?.totalSum) || 0),
        cashSum: this.formatMoney(parseFloat(this.data?.cashSum) || 0),
        creditCardSum: this.formatMoney(
          parseFloat(this.data?.creditCardSum) || 0
        ),
        bankSum: this.formatMoney(parseFloat(this.data?.bankSum) || 0),
      };
    },
  },
  methods: {
    ...mapActions(["fetchInvoicePaymentStats"]),
    formatMoney(x) {
      return formatCurrency(x);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateAndTime(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    setFiltersAndGetData({ dateFrom, dateTo, patientId, locationId }) {
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      this.patientId = patientId;
      this.locationId = locationId;
      this.getData();
    },
    async getData() {
      this.loading = true;

      const { data } = await this.fetchInvoicePaymentStats({
        doctorId: this.currentUser.doctor.id,
        patientId: this.patientId,
        locationId: this.locationId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });

      this.data = data;

      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
};
</script>
