<template>
  <div>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteExpense"
    />

    <v-dialog
      width="1200"
      v-model="showExpenseForm"
      @keydown.esc="showExpenseForm = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showExpenseForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <ExpenseForm
          v-if="showExpenseForm"
          :expense="expense"
          :title="formTitle"
          @expense-updated="handleExpenseUpdated"
          @expense-created="handleExpenseCreated"
          @on-cancel="showExpenseForm = false"
        />
      </v-card>
    </v-dialog>

    <p class="caption pasive--text text-right">
      {{ totalExpenses }} {{ totalExpenses == 1 ? "resultado" : "resultados" }}
    </p>

    <v-data-table
      :headers="listHeaders"
      :items="expenses"
      :loading="loading"
      loading-text="Cargando..."
      :no-results-text="'Cargando...'"
      :no-data-text="
        filter === '' ? 'No hay gastos registrados' : 'Gasto no encontrado'
      "
      :class="clickable ? 'row-pointer' : ''"
      item-key="id"
      item-class="text-center"
      disable-sort
      :footer-props="{
        itemsPerPageText: 'Resultados por pág.',
        itemsPerPageAllText: 'Todos',
        pageText: '',
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 60],
      }"
      :options.sync="options"
      :server-items-length="totalExpenses"
      @click:row="clickRow"
    >
      <template slot="item.total" scope="props">
        <span class="font-weight-bold">{{
          formatMoney(props.item.total)
        }}</span>
      </template>
      <template slot="item.date" scope="props">
        {{ formatDate(props.item.date) }}
      </template>
      <template slot="item.updatedAt" scope="props">
        {{ formatDateAndTime(props.item.updatedAt) }}
      </template>
      <template slot="item.location" scope="props">
        {{ props.item.location ? props.item.location.name : "-" }}
      </template>
      <template slot="item.comment" scope="props">
        {{ props.item.comment ? props.item.comment : "-" }}
      </template>
      <template slot="item.action" scope="props">
        <div class="d-flex justify-center">
          <v-menu bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mr-3" text icon>
                <v-icon size="20px">mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleClickEdit(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"
                      >mdi-square-edit-outline</v-icon
                    >
                    <div class="subtitle-2 font-weight-regular">Modificar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showWarning(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"> mdi-delete </v-icon>
                    <div class="subtitle-2 font-weight-regular">Eliminar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from "axios";
import { formatCurrency } from "../../helpers/formatters";
import moment from "moment";
import ExpenseForm from "./ExpenseForm.vue";
import WarningDialog from "../WarningDialog.vue";
import SearchInput from "../SearchInput.vue";

export default {
  name: "ExpenseList",
  components: {
    ExpenseForm,
    WarningDialog,
    SearchInput,
  },
  props: ["clickable", "headers"],
  data() {
    return {
      expanded: [],
      singleExpand: false,
      filter: "",
      loading: false,
      deleting: false,
      showExpenseForm: false,
      formTitle: "Crear",
      expense: null,
      options: {},
      totalExpenses: 0,
      locationId: null,

      // filters
      dateFrom: "",
      dateTo: "",
      terms: "",
      page: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapGetters(["expenses", "currentUser", "currentLocation"]),
    listHeaders() {
      return this.headers
        ? this.headers
        : [
            { text: "Fecha", value: "date" },
            {
              text: "Sede",
              value: "location",
            },
            {
              text: "Descripción",
              value: "description",
            },
            {
              text: "Importe",
              value: "total",
            },
            { text: "Comentario", value: "comment" },
            { text: "Última actualización", value: "updatedAt" },
            { text: "", value: "action", align: "center" },
          ];
    },
  },
  methods: {
    ...mapActions(["fetchExpenses"]),
    ...mapMutations([
      "setAlert",
      "removeExpense",
      "pushExpense",
      "updateExpense",
    ]),
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateAndTime(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    formatMoney(x) {
      return formatCurrency(x);
    },
    clickRow(row) {
      this.$emit("expense-click", row);
    },
    setFormToCreate() {
      this.expense = null;
      this.formTitle = "Crear";
      this.showExpenseForm = true;
    },
    handleExpenseCreated(item) {
      this.showExpenseForm = false;
      if (this.expenses.length == 1) {
        this.getData();
      }
      this.$emit("expense-created", item);
    },
    handleExpenseUpdated(item) {
      this.showExpenseForm = false;
    },
    handleClickEdit(item) {
      this.expense = item;
      this.formTitle = "Actualizar";
      this.showExpenseForm = true;
    },
    handleClickNew() {
      this.expense = null;
      this.formTitle = "Crear";
      this.showExpenseForm = true;
    },
    showWarning(item) {
      this.expense = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar ${this.expense.description}?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deleteExpense() {
      try {
        this.deleting = true;
        const res = await axios.delete(`/api/expense/${this.expense.id}`);
        this.warning = false;
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.removeExpense(this.expense);
        this.$emit("Expense-deleted");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },
    setFiltersAndGetData({ dateFrom, dateTo, term, patientId, locationId }) {
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      this.term = term;
      this.locationId = locationId;
      if (!this.loading) {
        this.getData();
      }
    },
    async getData() {
      this.loading = true;

      const data = await this.fetchExpenses({
        term: this.term,
        page: this.page,
        pageSize: this.pageSize,
        doctorId: this.currentUser.doctor.id,
        locationId: this.locationId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });

      this.totalExpenses = data?.count || 0;
      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler(value) {
        if (value) {
          this.page = value.page || 1;
          this.pageSize = value.itemsPerPage || 15;
        }
      },
      deep: true,
    },
    currentLocation: {
      handler() {
        this.locationId =
          this.currentLocation.id < 0 ? null : this.currentLocation.id;
      },
    },
    locationId: {
      handler() {
        this.getData();
      },
    },
  },
  mounted() {
    this.locationId =
      this.currentLocation.id < 0 ? null : this.currentLocation.id;
  },
};
</script>
