<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Finanzas</h2>
        <v-divider class="mt-2"></v-divider>
        <div class="mb-8 d-flex">
          <v-tabs
            v-model="tab"
            icons-and-text
            height="40"
            color="secondary"
            slider-color="secondary"
          >
            <v-tab v-for="(item, index) in visibleTabs" :key="index">
              <div class="caption font-weight-medium d-flex align-center">
                <div>{{ item.label }}</div>
              </div>
            </v-tab>
          </v-tabs>
        </div>

        <div class="d-md-flex align-start justify-space-between">
          <div class="d-md-flex align-center">
            <div class="mr-5">
              <RangeDateFilter
                @filter="handleFilterByDate"
                label="Fecha"
                labelRange="Fecha"
              />
            </div>
            <div v-if="tab === 0 || tab === 1 || tab === 2" class="mr-5">
              <SearchInput @search="handleSearchInput" />
            </div>
            <div class="d-flex align-center mb-6">
              <v-btn
                v-if="tab === 0 || tab === 2"
                title="Crear un nuevo registro"
                color="primary"
                small
                depressed
                @click="handleClickNew"
              >
                nuevo
              </v-btn>
            </div>
          </div>
        </div>

        <InvoiceList
          v-show="tab === 0"
          ref="InvoiceListRef"
          :clickable="true"
        />

        <div v-show="tab === 1">
          <InvoicePaymentStats class="mt-8" ref="InvoicePaymentStatsRef" />
          <InvoicePaymentList
            class="mt-8"
            ref="InvoicePaymentListRef"
            @item-deleted="filterApplied"
          />
        </div>

        <ExpenseList v-show="tab === 2" ref="ExpenseListRef" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import RangeDateFilter from "../components/RangeDateFilter.vue";
import SearchInput from "../components/SearchInput.vue";
import InvoiceList from "../components/Payment/InvoiceList.vue";
import InvoicePaymentStats from "../components/Payment/InvoicePaymentStats.vue";
import InvoicePaymentList from "../components/Payment/InvoicePaymentList.vue";
import ExpenseList from "../components/Expense/ExpenseList.vue";

export default {
  name: "Finance",
  components: {
    RangeDateFilter,
    SearchInput,
    InvoiceList,
    InvoicePaymentStats,
    InvoicePaymentList,
    ExpenseList,
  },
  data() {
    return {
      todayDate: "",
      from: "",
      to: "",
      terms: "",
      tab: 0,
      tabs: [
        {
          value: 0,
          label: "Facturas",
          dataFetched: false,
        },
        {
          value: 1,
          label: "Pagos / ingresos",
          dataFetched: false,
        },
        {
          value: 2,
          label: "Gastos",
          dataFetched: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentLocation"]),
    visibleTabs() {
      const noStantardSubscription =
        this.currentUser.doctor.subscription == "pro" ||
        this.currentUser.doctor.subscription == "premium";

      return this.tabs.filter((tab) => {
        if (tab.value == 2) {
          return noStantardSubscription;
        }

        return true;
      });
    },
  },
  methods: {
    ...mapMutations(["setAppointments"]),
    handleFilterByDate({ from, to }) {
      this.from = from || this.todayDate;
      this.to = to || this.todayDate;
      this.getData();
      this.filterApplied();
    },
    handleSearchInput(term) {
      this.terms = term;
      this.getData();
      this.filterApplied();
    },
    handleClickNew() {
      if (this.tab === 0) {
        this.$refs.InvoiceListRef.handleClickNew({ choosePatient: true });
      }

      if (this.tab == 2) {
        this.$refs.ExpenseListRef.setFormToCreate();
      }
    },
    getData() {
      this.dataFetched();

      const locationId =
        this.currentLocation?.id && this.currentLocation?.id !== -1
          ? this.currentLocation?.id
          : null;

      if (this.tab === 0) {
        this.$refs.InvoiceListRef.setFiltersAndGetData({
          dateFrom: this.from,
          dateTo: this.to,
          term: this.terms,
          locationId,
        });
      }

      if (this.tab === 1) {
        this.$refs.InvoicePaymentStatsRef.setFiltersAndGetData({
          dateFrom: this.from,
          dateTo: this.to,
          locationId,
        });
        this.$refs.InvoicePaymentListRef.setFiltersAndGetData({
          dateFrom: this.from,
          dateTo: this.to,
          term: this.terms,
          locationId,
        });
      }

      if (this.tab === 2) {
        this.$refs.ExpenseListRef.setFiltersAndGetData({
          dateFrom: this.from,
          dateTo: this.to,
          term: this.terms,
          locationId,
        });
      }
    },
    filterApplied() {
      this.tabs = this.tabs.map((tab) => ({ ...tab, dataFetched: false }));
    },
    dataFetched() {
      this.tabs = this.tabs.map((tab) => {
        if (this.tab === tab.value) {
          return {
            ...tab,
            dataFetched: true,
          };
        }

        return tab;
      });
    },
  },
  watch: {
    tab: function (value) {
      const tab = this.tabs.filter((tab) => tab.value === value)[0];
      if (!tab.dataFetched) {
        this.getData();
      }
    },
    currentLocation: function () {
      this.getData();
    },
  },
  mounted() {
    this.todayDate = moment().startOf("day").toDate().toJSON().slice(0, 10);
    this.from = this.todayDate;
    this.to = this.todayDate;
    this.getData();
  },
};
</script>
